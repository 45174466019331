/*
// Web Fonts from fontspring.com
//
// All OpenType features and all extended glyphs have been removed.
// Fully installable fonts can be purchased at http://www.fontspring.com
//
// The fonts included in this stylesheet are subject to the End User License you purchased
// from Fontspring. The fonts are protected under domestic and international trademark and
// copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
// distributing this font software.
//
// (c) 2010-2016 Fontspring
//
//
// The fonts included are copyrighted by the vendor listed below.
//
// Vendor:      Mark Simonson Studio
// License URL: https://www.fontspring.com/licenses/mark-simonson-studio/webfont
*/

//
// Core UCalgary Fonts: Licensed for 50,000,000 Pageviews/Year
//

$font_path: "../fonts/" !default;

//
// Proxima Nova:
// font-weight values: 200 (light), 400 (regular), 500 (medium), 600 (semibold) and 700 (bold)
//

// Proxima Nova Light

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-light-webfont.eot");
  src:
    url($font_path + "proximanova-light-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-light-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-light-webfont.woff") format("woff"),
    url($font_path + "proximanova-light-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-light-webfont.svg#proxima_nova_ltlight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-lightit-webfont.eot");
  src:
    url($font_path + "proximanova-lightit-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-lightit-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-lightit-webfont.woff") format("woff"),
    url($font_path + "proximanova-lightit-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-lightit-webfont.svg#proxima_novalight_italic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: fallback;
}

// Proxima Nova Regular

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-regular-webfont.eot");
  src:
    url($font_path + "proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-regular-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-regular-webfont.woff") format("woff"),
    url($font_path + "proximanova-regular-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-regularit-webfont.eot");
  src:
    url($font_path + "proximanova-regularit-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-regularit-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-regularit-webfont.woff") format("woff"),
    url($font_path + "proximanova-regularit-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-regularit-webfont.svg#proxima_novaregular_italic") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

// Proxima Nova Medium

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-medium-webfont.eot");
  src:
    url($font_path + "proximanova-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-medium-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-medium-webfont.woff") format("woff"),
    url($font_path + "proximanova-medium-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-medium-webfont.svg#proxima_novamedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-mediumit-webfont.eot");
  src:
    url($font_path + "proximanova-mediumit-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-mediumit-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-mediumit-webfont.woff") format("woff"),
    url($font_path + "proximanova-mediumit-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-mediumit-webfont.svg#proxima_novamedium_italic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

// Proxima Nova Semibold

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-semibold-webfont.eot");
  src:
    url($font_path + "proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-semibold-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-semibold-webfont.woff") format("woff"),
    url($font_path + "proximanova-semibold-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-semibold-webfont.svg#proxima_nova_rgbold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-semiboldit-webfont.eot");
  src:
    url($font_path + "proximanova-semiboldit-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-semiboldit-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-semiboldit-webfont.woff") format("woff"),
    url($font_path + "proximanova-semiboldit-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-semiboldit-webfont.svg#proxima_novabold_italic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: fallback;
}

// Proxima Nova Bold

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-bold-webfont.eot");
  src:
    url($font_path + "proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-bold-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-bold-webfont.woff") format("woff"),
    url($font_path + "proximanova-bold-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Proxima Nova";
  src: url($font_path + "proximanova-boldit-webfont.eot");
  src:
    url($font_path + "proximanova-boldit-webfont.eot?#iefix") format("embedded-opentype"),
    url($font_path + "proximanova-boldit-webfont.woff2") format("woff2"),
    url($font_path + "proximanova-boldit-webfont.woff") format("woff"),
    url($font_path + "proximanova-boldit-webfont.ttf") format("truetype"),
    url($font_path + "proximanova-boldit-webfont.svg#proxima_novabold_italic") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}
